import React from "react";
import PropTypes from "prop-types";

import Column from "src/components/Column";
import Header from "src/components/Header";

import css from "./index.module.scss";


const BottomRow = ({ page: { config: { bottomRow }}}) => {
  
  const renderPrices = list => {
    return list.sort((a, b) => a.order - b.order)
      .map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.vehicleType}</td>
            <td>€{item.brakeTest.toFixed(2)}</td>
            <td>€{item.brakeTestAndInspection.toFixed(2)}</td>
          </tr>
        );
      });
  };
  
  return (
    <Column className={css.bottomRow} direction="cc">
      <Header primaryText={bottomRow.headingPrimary} secondaryText={bottomRow.headingSecondary}/>
      <table>
        <thead>
        <tr>
          <td>Vehicle Type</td>
          <td>Brake Test</td>
          <td>Brake Test + Inspection</td>
        </tr>
        </thead>
        <tbody>
        {renderPrices(bottomRow.prices)}
        </tbody>
      </table>
    </Column>
  );
};

BottomRow.propTypes = {
  page: PropTypes.object.isRequired
};

export default BottomRow;