import React from "react";
import PropTypes from "prop-types";

import Column from "src/components/Column";
import Header from "src/components/Header";

import css from "./index.module.scss";


const MiddleRowOne = ({ page: { config: { middleRowOne }}}) => {
  
  const renderLevels = levels => {
    return levels.sort((a, b) => a.order - b.order)
      .map((level, i) => {
        return (
          <Column key={i} direction="cc">
            <h3>{level.heading}</h3>
            <ul>
              {level.bullets.sort((a, b) => a.order - b.order).map((bullet, j) => <li key={j}>{bullet.text}</li>)}
            </ul>
          </Column>
        );
      });
  };
  
  return (
    <Column className={css.middleRow} direction="cc">
      <Header primaryText={middleRowOne.headingPrimary} secondaryText={middleRowOne.headingSecondary}/>
      <Column className={css.levels}>
        {renderLevels(middleRowOne.levels)}
      </Column>
    </Column>
  );
};

MiddleRowOne.propTypes = {
  page: PropTypes.object.isRequired
};

export default MiddleRowOne;