import React from "react";
import PropTypes from "prop-types";

import Column from "src/components/Column";
import Header from "src/components/Header";

import css from "./index.module.scss";


const MiddleRowTwo = ({ page: { config: { middleRowTwo }}}) => {
  
  const renderPrices = list => {
    return list.sort((a, b) => a.order - b.order)
      .map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.vehicleType}</td>
            <td>€{item.levelOne.toFixed(2)}</td>
            <td>€{item.levelTwo.toFixed(2)}</td>
            <td>€{item.preTest.toFixed(2)}</td>
          </tr>
        );
      });
  };
  
  return (
    <Column className={css.middleRow} direction="cc">
      <Header primaryText={middleRowTwo.headingPrimary} secondaryText={middleRowTwo.headingSecondary}/>
      <table>
        <thead>
        <tr>
          <td>Vehicle Type</td>
          <td>Level 1</td>
          <td>Level 2</td>
          <td>Full Pre-Test</td>
        </tr>
        </thead>
        <tbody>
        {renderPrices(middleRowTwo.prices)}
        </tbody>
      </table>
    </Column>
  );
};

MiddleRowTwo.propTypes = {
  page: PropTypes.object.isRequired
};

export default MiddleRowTwo;