import React from "react";

import Column from "src/components/Column";
import {TopRow, MiddleRowOne, MiddleRowTwo, BottomRow} from "src/components/pages/Inspections";
import TestCentreNavigation from "src/containers/TestCentreNavigation";
import {withSEO} from "src/hoc/SEO";
import { pages } from "src/config";


const InspectionsPage = props => {
  return (
    <main>
      <Column direction="cc">
        <TestCentreNavigation page={pages.testCentre} location={props.location.pathname}/>
        <TopRow page={pages.testCentre.pages.inspections}/>
        <MiddleRowOne page={pages.testCentre.pages.inspections}/>
        <MiddleRowTwo page={pages.testCentre.pages.inspections}/>
        <BottomRow page={pages.testCentre.pages.inspections}/>
      </Column>
    </main>
  );
};

export default withSEO({
  title: pages.testCentre.pages.inspections.title
})(InspectionsPage);
